body {
  background-color: #282c34;
}

.white-text {
  color: white;
}

.hot-pink-text, a:hover.hot-pink-text {
  color: #e83e8c;
}

.row>img, .row>p {
  margin: 4px 0 4px 14px;
}

.vertical-timeline-element-date {
  color: #ffffff !important;
}

.red .vertical-timeline-element-content {
  border-top: 3px solid rgb(233, 30, 99);
}
.red .vertical-timeline-element-icon {
  background: rgb(233, 30, 99);
}
.red .vertical-timeline-element-title {
  color: rgb(233, 30, 99);
}

.blue .vertical-timeline-element-content {
  border-top: 3px solid rgb(33, 150, 243);
}
.blue .vertical-timeline-element-icon {
  background: rgb(33, 150, 243);
}
.blue .vertical-timeline-element-title {
  color: rgb(33, 150, 243);
}

.green .vertical-timeline-element-content {
  border-top: 3px solid rgb(16, 204, 82);
}
.green .vertical-timeline-element-icon {
  background: rgb(16, 204, 82);
}
.green .vertical-timeline-element-title {
  color: rgb(16, 204, 82);
}

.gray .vertical-timeline-element-content {
  border-top: 3px solid #777;
}
.gray .vertical-timeline-element-icon {
  background: #777;
}
.gray .vertical-timeline-element-title {
  color: #777;
}
.opaque {
  opacity: 0.7;
}